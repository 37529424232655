body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.menu-button{
  padding-left: 2rem !important;
  font-size: 1rem !important;
  line-height: 1.5em;
}
.menu-button .menu-ico{
  
  font-size: 1.5rem !important;
  line-height: 1.6em;
  color: rgba(0, 0, 0, 0.54)
}
.menu-button span{
  padding-right: 1em !important; 
}
